<template>
    <div class="solution4" id="jumpPoint">
        <div class="banner">
            <div class="banner_title">
                <p class="p1">电&nbsp;&nbsp;子&nbsp;&nbsp;电&nbsp;&nbsp;气&nbsp;&nbsp;配&nbsp;&nbsp;套&nbsp;&nbsp;解&nbsp;&nbsp;决&nbsp;&nbsp;方&nbsp;&nbsp;案</p>
                <p class="p2">超 越 软 件 智 造 未 来</p>
            </div>
        </div>

        <div class="boxes1">
            <p class="pub_title">简介</p>
            <div class="center">
                <div class="left">
                    <img src="./../../assets/Solution4/img1.png" alt="">
                </div>
                <div v-html="content1" class="content">{{content1}}</div>
            </div>
        </div>

        <div class="boxes2">
            <p class="pub_title" style="margin-bottom:0.3rem;">关键问题</p>
            <img src="./../../assets/Solution4/img2.png" alt="">
            <p class="font1">工作效率低，重复率高且复杂</p>
            <div class="row row1">质量损失、质量改善难以分析和考核</div>
            <div class="row row2">质量异常难以追溯（涉及人员、设备、材料、环境、工艺等）</div>
        </div>

        <div class="boxes3">
            <p class="pub_title">解决方案优势</p>
            <div class="center">
                <div class="per_row">
                    <div class="left">
                        <img src="./../../assets/Solution4/img3.png" alt="">
                    </div>
                    <div class="right">
                        <p>MES选型标准</p>
                        <div class="line"></div>
                        <div class="content">
                            <span>MES厂商必须具备汽配行业实施经验</span>
                            <span>MES厂商必须熟悉ERP</span>
                            <span>MES厂商必须具备信息化整体规划的能力</span>
                            <span>MES必须能够现有设备互联</span>
                        </div>
                    </div>
                </div>
                <div class="per_row">
                    <div class="left">
                        <img src="./../../assets/Solution4/img4.png" alt="">
                    </div>
                    <div class="right">
                        <p>项目概况</p>
                        <div class="line"></div>
                        <div class="content">
                            <span>企业规模：4000人&emsp;&emsp;&emsp;&emsp;&nbsp;实施时间：2013.09</span>
                            <span>实施周期：6个月&emsp;&emsp;&emsp;&emsp;&emsp;实施范围：印刷车间、前加工车间、组立车间</span>
                            <span class="font_align">主要工艺：玻璃清洗、碳印刷、银线印刷、透明印刷、碳印刷、白线印刷、 保护膜粘贴、ACF切割+热封、FPC热封1(脉冲式热封机)、電性検査1(电容)、 (A+B+CG)貼合(整枚贴合机)、加温加压(55℃)、变形检查</span>
                            <span>管控模式：批次管控</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="boxes4">
            <p class="pub_title">实施收益</p>
            <div class="center">
                <div class="left">
                    <div v-html="content2" class="content">{{content2}}</div>
                </div>
               <div class="right">
                    <img src="./../../assets/Solution4/img5.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name:'Solution4',
    components:{
        
    },
    data(){
        return {
            content1:'冠智电子于2003年02月成立：隶属GUNZE电子事业部,公司生产和销售新型平板显示器件（新型平板电子智能感应触摸屏、智能感应触摸胶片、智能感应触摸玻璃片）等。\n&emsp;&emsp;产品主要应用于手机、笔记本电脑、汽车导航系统（GPS)等；\n&emsp;&emsp;主要客户有SONY(索尼)、LENOVO(联想)、PANASONIC（松下）、CANON（佳能）等',
            content2:'质量追溯电子化：通过电脑实现生产全过程追溯\n生产过程防错：油墨、网版、刮刀、生产设备、人员防错 \n车间现场无纸化：减少95%现场纸张（无尘纸） \n生产报表自动化：90%的报表自动计算、邮件分发 \n日成本自动计算：日成本精确到工序、批次',
        }
    },
    
    created(){
        this.$nextTick(()=>{
            this.tojumpPoint()
        })
    },

    methods:{
        tojumpPoint(){
            document.getElementById('jumpPoint').scrollIntoView({
                behavior:'smooth',
                block:'start'
            })
        }
    },
}
</script>

<style scoped lang='scss'>
@import './Solution4.scss'
</style>
